var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _vm.authorized
        ? _c(
            "v-container",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize",
                },
              ],
              staticClass: "ma-0 pa-0",
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "fill" } },
                [
                  _c("v-breadcrumbs", {
                    attrs: { items: _vm.breadcrumbs },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-breadcrumbs-item",
                                {
                                  attrs: {
                                    to: item.to,
                                    disabled: item.disabled,
                                    link: "",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: !item.disabled
                                        ? "primary--text"
                                        : "",
                                    },
                                    [_vm._v(_vm._s(item.text))]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1394317943
                    ),
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { staticClass: "pl-0", attrs: { cols: "3" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-7",
                        class: _vm.body,
                        attrs: {
                          label: "Filter Features",
                          placeholder: "Filter By Position or Text",
                          "prepend-inner-icon": "mdi-filter-outline",
                          outlined: "",
                          dense: "",
                          "background-color": "input",
                          clearable: "",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.getFilteredFeatures.apply(
                              null,
                              arguments
                            )
                          },
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("AdActions", {
                        attrs: { ad: _vm.selectedAd, adParty: _vm.adParty },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: { color: "primary", dark: "" },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-dots-vertical"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1600196812
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-tabs",
                {
                  attrs: {
                    "background-color": "white",
                    grow: "",
                    height: "35",
                    "hide-slider": "",
                  },
                  model: {
                    value: _vm.pageIndex,
                    callback: function ($$v) {
                      _vm.pageIndex = $$v
                    },
                    expression: "pageIndex",
                  },
                },
                _vm._l(_vm.pages, function (page, index) {
                  return _c(
                    "v-tab",
                    {
                      key: index,
                      staticClass: "text-capitalize",
                      on: {
                        change: function ($event) {
                          ;(_vm.currentPage = page), _vm.getAdPageFeatures(page)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(page.name) + " ")]
                  )
                }),
                1
              ),
              _c("v-data-table", {
                class: _vm.table,
                staticStyle: { cursor: "pointer" },
                attrs: {
                  expanded: _vm.expanded,
                  "footer-props": { "items-per-page-options": _vm.pageSizes },
                  headers: _vm.featureHeaders,
                  height: _vm.tableHeight,
                  "items-per-page": _vm.pageSize,
                  items: _vm.adFeatures,
                  loading: _vm.loading,
                  dense: "",
                  "fixed-header": "",
                  "item-key": "id",
                  "loading-text": "Loading Features...Please Wait",
                  "no-data-text": "No Features to Display",
                  "no-results-text": "No Matching Features Found",
                  "sort-by": _vm.sortBy,
                  "sort-desc": false,
                },
                on: {
                  "update:expanded": function ($event) {
                    _vm.expanded = $event
                  },
                  "click:row": _vm.viewFeature,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.promo_item_group_ids`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(item.promo_item_group_ids.length) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.non_promo_items`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " + _vm._s(item.non_promo_items.length) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.audit.updated_on`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDateDisplay(item.audit.updated_on)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.ad_start_date`,
                      fn: function ({}) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDateDisplay(
                                  _vm.selectedAd.ad_start_date
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.ad_end_date`,
                      fn: function ({}) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatDateDisplay(
                                  _vm.selectedAd.ad_end_date
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.classification`,
                      fn: function ({}) {
                        return [_vm._v(" " + _vm._s(_vm.classification) + " ")]
                      },
                    },
                    {
                      key: `item.notes`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  ;(_vm.selectedItem = item),
                                    (_vm.component = "Notes"),
                                    (_vm.modal = true),
                                    (_vm.isAd = false)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-message-outline")])],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.authorized
        ? _c(
            "v-dialog",
            {
              attrs: {
                justify: "center",
                value: !_vm.authorized,
                persistent: "",
                "max-width": "400",
              },
            },
            [
              _c("v-card", { attrs: { color: "#fafafa" } }, [
                _c(
                  "section",
                  { staticClass: "ma-0 pa-6" },
                  [
                    _c(
                      "v-row",
                      { attrs: { justify: "center", dense: "" } },
                      [
                        _c(
                          "v-icon",
                          { attrs: { "x-large": "", color: "red" } },
                          [_vm._v("mdi-alert-decagram")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "pa-4",
                            staticStyle: { "text-align": "center" },
                            attrs: { "justify-content": "center" },
                          },
                          [
                            _vm._v(
                              " You do not have permission to view " +
                                _vm._s(_vm.selectedAd.ad_name) +
                                ". If you believe you are seeing this message in error, contact Support. "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.modal
        ? _c("FeatureView", {
            attrs: {
              value: _vm.modal,
              selectedAd: _vm.selectedAd,
              selectedFeature: _vm.selectedFeature,
              pages: _vm.pages,
              pagePositions: _vm.pagePositions,
              currentPage: _vm.currentPage,
              getAdPageFeatures: _vm.getAdPageFeatures,
              adFeatures: _vm.adFeatures,
              readonly: _vm.readonly,
              adParty: _vm.adParty,
              defaultParty: _vm.adParty,
              parties: _vm.adGroups,
              layouts: _vm.layouts,
            },
            on: {
              "update:value": function ($event) {
                _vm.modal = $event
              },
              refresh: _vm.getAdPageFeatures,
            },
          })
        : _vm._e(),
      !_vm.readonly
        ? _c(
            "v-fab-transition",
            [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "primary mr-6 mb-12",
                                  style: _vm.floatingButton,
                                  attrs: {
                                    absolute: "",
                                    bottom: "",
                                    right: "",
                                    fab: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.modal = true
                                    },
                                  },
                                },
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-plus")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1227571642
                  ),
                },
                [_c("span", [_vm._v("Create Feature")])]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }